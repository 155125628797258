.ekim--login--btn--group {
  margin: 1.5em 0 0 0;
}

.ekim--login--details-btn {
  color: $primary;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1em;
}

.ekim--login--details-text {
  display: none;
}
