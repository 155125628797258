$btnBorderRadius: 3px;
$btnMargin: .3em;

a.btn, input.btn, button.btn, label.btn {
    background: $grey;
    display: inline-block;
    color: $primary_darker;
    cursor: default;
    border-radius: $btnBorderRadius;
    padding: 0.5em 0.8em;
    text-decoration: none;
    border-style: none;
    user-select: none;
    display: inline-block;
    margin: $btnMargin $btnMargin $btnMargin 0;
    font-weight: bold;

    vertical-align: middle;

    &:hover {
        background-color: $grey_dark;
        text-decoration: none;
    }
    &:active {
        background-color: $grey_darker;
    }

    &.btn-icon {
        padding: 0.2em;
        font-size: 1.4em;
        color: #FFFFFF;
        margin: 0;
    }

    &.btn-primary {
        background: $primary;
        color: #FFFFFF;

        &:hover {
            background: $primary;
            //  background-image: linear-gradient(to bottom, $primary_light, $primary);
            background-color: $primary_dark;
            text-decoration: none;
        }
        &:active {
            background-color: $primary_darker;
        }
    }

    .btn--radios & {
        background: $grey_light;
        color: $grey_darker;

        &:hover {
            background: $grey;
        }
        &.btn--radios--selected {
            &, &:hover {
                background: #398fd1;
                box-shadow: 0 0 4px 1px #2974ae inset;
                color: #FFFFFF;
            }
        }
    }
}


.btn--label {
/*    padding: .6em;
    margin: $btnMargin;*/
    padding: 0 1em;
    display: inline-block;
    font-weight: bold;
    color: $primary_dark;
    vertical-align: middle;
/*    line-height: 2em;*/
    top: 6px;
}

.btn--radios {

    // necessary because buttons are floated
    overflow: hidden;

    .btn {
        border-radius: 0;
        margin-left: 0;
        margin-right: 0;

        // necessary to avoid gap between buttons
        float: left;
    }
    input {
        display: none;
    }
    .btn:nth-child(1) {
        border-top-left-radius: $btnBorderRadius;
        border-bottom-left-radius: $btnBorderRadius;
    }
    .btn:last-child {
        border-top-right-radius: $btnBorderRadius;
        border-bottom-right-radius: $btnBorderRadius;
    }

    // show buttons under instead of next to each other on small screens
    &.btn--radios-collapse {
        @media ($mediaQueryPhone) {
            .btn {
                border-radius: 0;
                display: block;
                float: none;
                margin: 0 $btnMargin;
            }
            .btn:nth-child(1) {
                border-top-left-radius: $btnBorderRadius;
                border-top-right-radius: $btnBorderRadius;
            }
            .btn:last-child {
                border-bottom-left-radius: $btnBorderRadius;
                border-bottom-right-radius: $btnBorderRadius;
            }
        }
    }
}
