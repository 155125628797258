$primary: #398FD1;
$primary_light: lighten($primary, 10);
$primary_dark: darken($primary, 10);
/*$primary_dark: #39424C;*/
$primary_darker: #194659;

/*$grey: rgb(212, 221, 221);*/
$grey: #dddddd;
$grey_light: rgb(244, 244, 244);
$grey_dark: darken($grey, 10);
$grey_darker: darken($grey, 20);

$text: #808285;
$error: #DF2111;

$offline: red;
$online: green;

$blue_light:rgb(203,227,250);

$mediaQuery1: 'max-width: 400px';

$mediaQuerySmall: 'max-width: 25em';
$mediaQueryPhone: 'max-width: 45em';
$mediaQueryPhoneBigger: 'max-width: 55em';
$mediaQueryTablet: 'max-width: 70em';