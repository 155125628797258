.ekim--cart-showNetto {
    .ekim--cart--brutto,
    .ekim--productList--total-brutto {
        display: none;
    }
}
.ekim--cart-showBrutto {
    .ekim--cart--netto,
    .ekim--productList--total-netto {
        display: none;
    }
}