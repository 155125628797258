.apxDialog {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    z-index: 999;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    &.apxDialog-modal {
        background: rgba(255, 255, 255, .6);
        background: radial-gradient(ellipse farthest-corner at center, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.7) 100%)
    }
}

.apxDialog--popup {
    background: white;
    border: 1px solid $grey;
    color: black;
    max-width: 40%;

    // Fix problem where text is sometimes invisible
    backface-visibility: hidden;
    text-rendering: geometricPrecision;

    z-index: 1000;

    @media ($mediaQueryPhone) {
        max-width: 80%;
    }
}

.apxDialog--content {
    margin: 1.5em;

    p.apxDialog--content--prompt {
        margin: 0 0 .4em 0;
    }
}

.apxDialog--footer {
    border-top: 1px solid $grey;
    background: $grey_light;

    text-align: right;
    padding: .2em 1em;

    .btn {
        margin-left: 1em;
    }
}


.animated {
    animation-duration: .5s;
    animation-fill-mode: both;
    transform: translateZ(0);
}

.animated-short {
    animation-duration: .3s;
    animation-fill-mode: both;
    transform: translateZ(0);
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        display: block;
    }
}

.fadeIn {
    animation-name: fadeIn;
}


@keyframes fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.fadeOut {
    animation-name: fadeOut;
}


@keyframes scaleIn {
    0% {
        opacity: 0;
        transform: scale(.75);
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}


.scaleIn {
    animation-name: scaleIn;
}

@keyframes scaleOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        transform: scale(.75);
    }
}

.scaleOut {
    animation-name: scaleOut;
}