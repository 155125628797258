$arrow_border_width: 18px;

.ekim--breadcrumbs--container {
    min-height: 90px;
    padding: 0 90px;
}

.ekim--breadcrumbs {
    list-style: none;
/*    margin: -6em 0 4em;*/
    padding: 0 $arrow_border_width 0 0;

    overflow: hidden;
    user-select: none;

    bottom: 1em;
}
.ekim--breadcrumbs--link {
    background: $grey_light;
    color: $primary;
    cursor: default;
    transition: background-color 0.5s;
    font-size: 1.2em;

    line-height: 1.75em;
    margin: 0 .3em .2em $arrow_border_width;
    padding: 3px 13px;
    // max-width: 35%;
    max-width: 22%;
    height: 30px;

    position: relative;
    float: left;

    &:before,
        &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        transition: border-color 0.5s;
    }
    &:before {
        right: 100%;

        border-left: $arrow_border_width solid transparent;
        border-top: $arrow_border_width solid $grey_light;
        border-bottom: $arrow_border_width solid $grey_light;
    }

    &:after {
        left: 100%;

        border-left: $arrow_border_width solid $grey_light;
        border-top: $arrow_border_width solid transparent;
        border-bottom: $arrow_border_width solid transparent;
    }
}
.ekim--breadcrumbs--link.ekim--breadcrumbs--link-first {
    margin-left: 0;

    &:before {
        display: none;
    }
}
.ekim--breadcrumbs--link--value {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 100%;
}
.ekim--breadcrumbs--link-hasValue {
    line-height: 1em;

    a {
        line-height: 0;
    }

    .ekim--breadcrumbs--link--name {
        display: block;

        font-size: .65em;
        line-height: 1.2em;
    }

    .ekim--breadcrumbs--link--value {
        line-height: 1.1em;
    }
}
.ekim--breadcrumbs--link.ekim--breadcrumbs--link-active {
    background: $primary;
    a {
        color: white;
    }

    &:before {
        border-color: $primary transparent $primary transparent;
    }

    &:after {
        border-color: transparent transparent transparent $primary;
    }

    &.ekim--breadcrumbs--link-hasLink {

        &:hover {
            background: $primary_dark;
            cursor: pointer;

            a {
                color: white;
            }

            &:before {
                border-color: $primary_dark transparent $primary_dark transparent;
            }

            &:after {
                border-color: transparent transparent transparent $primary_dark;
            }
        }

        &:active {
            background-color: $primary_dark;

            &:before {
                border-color: $primary_dark transparent $primary_dark transparent;
            }

            &:after {
                border-color: transparent transparent transparent $primary_dark;
            }
        }
    }
}

@media screen and ($mediaQueryPhoneBigger) {
    .ekim--breadcrumbs--container {
        padding: 0 45px;
    }
}

@media ($mediaQueryPhone) {
    .ekim--breadcrumbs--container {
        padding: 1em 10px 0;
        min-height: 0;
        height: auto;
    }
    .ekim--breadcrumbs {
        border-top: 2px solid $grey;
        margin: 0;
        padding: 0;
        overflow: visible;

        &.ekim--breadcrumbs-4 {
            .ekim--breadcrumbs--link {
                width: 25%;
            }
        }

        &.ekim--breadcrumbs-5 {
            .ekim--breadcrumbs--link {
                width: 20%;
            }
        }
    }
    .ekim--breadcrumbs--link {
        box-sizing: border-box;
        line-height: 2em;
        margin: 0;
        padding: 0;
        text-align: center;

        &:after {
            display: none;
        }

        &:before,
        &.ekim--breadcrumbs--link-first:before {
            display: block;
            border: 1px solid;
            border-color: $grey_dark !important;
            background-color: white;
            border-radius: 50%;

            height: 10px;
            width: 10px;

            position: absolute;
            top: -7px;
            left: 0;
            right: 0;
            margin: auto;
        }

        a {
            display: block;
            padding: 1.5em 0 .5em;
            // make sure the link covers indicator above it
            position: relative;
            top: -1em;
        }

        &,
        &.ekim--breadcrumbs--link-active {

            &,
            &.ekim--breadcrumbs--link-hasLink:hover {
                background: none;

                a {
                    color: $grey_darker;
                }
            }

            a {
                color: $grey_darker;
            }
        }
        &.ekim--breadcrumbs--link-active {

            &:before {
                background-color: $grey;
            }
        }
        &.ekim--breadcrumbs--link-current {
            color: $primary;

            &:before {
                background-color: $primary;
                border-color: $primary !important;
            }
        }

        .ekim--breadcrumbs--link--name {
            display: block;
            font-size: .8em;
            max-width: 100%;

            line-height: 1.2;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .ekim--breadcrumbs--link--value {
        display: none;
    }
}
