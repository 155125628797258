.ekim--form {
    fieldset {
        border-color: #ddd;
        border-width: 1px;
        margin-bottom: 1em;
    }
}

.ekim--form--group {
    display: block;
    clear: both;
    padding: .2em;

    &.ekim--form--group-buttonleft {
        clear: none;
        float: left;
    }
    &.ekim--form--group-button {
        clear: none;
        float: right;
    }

    .ekim--form--input,
    .ekim--form--input input {
        display: inline-block;
        outline-color: $grey_darker;
    }
    input.ekim--form--input:focus {
        outline-style: solid;
        outline-width: 1px;
    }
}

.ekim--form--group .ekim--form--input {
    padding: .3em;
}

.ekim--form--group input.ekim--form--input,
.ekim--form--group textarea.ekim--form--input {
    border: 1px solid $grey;
    box-sizing: border-box;
    width: 67%;

    &[disabled] {
        background: $grey;
    }
}

.ekim--form--valid,
.ekim--form--valid input {
//    border-color: green !important;
//    outline-color: green !important;
}
.ekim--form--invalid,
.ekim--form--invalid input {
    border-color: $error !important;
    outline-color: $error !important;

}
label.ekim--form--invalid {
    color: $error;
}

.ekim--form--label {
    display: block;
    float: left;
    line-height: 2em;
    //	display: inline-block;
    width: 33%;
}

input[type=checkbox] {
    /*    visibility: hidden;*/
    margin-right: .2em;

    &:after {
        content: "";
        display: block;
        visibility: visible;
        background: transparent url(../img/checkbox.png) center 0% no-repeat;
        background-size: 100% auto;
        width: 15px;
        height: 15px;

        // fix to make sure pseudo element is completely over input
        margin-left: -1px;
    }
    &:checked:after {
        background-position: center 33%;
    }
    .ekim--form--invalid & {
        &:after {
            background-position: center 67%;
        }
        &:checked:after {
            background-position: center 100%;
        }
    }
    &[disabled] {
        opacity: .5;
    }
}


input[type=radio] {
    /*    visibility: hidden;*/
    margin-right: .2em;

    &:after {
        content: "";
        display: block;
        visibility: visible;
        // background: transparent url(../img/radio.png) center 0% no-repeat;
        background-size: 100% auto;
        width: 15px;
        height: 15px;

        // fix to make sure pseudo element is completely over input
        margin-left: -1px;
    }
    &:checked:after {
        background-position: center 33%;
    }
    .ekim--form--invalid & {
        &:after {
            background-position: center 67%;
        }
        &:checked:after {
            background-position: center 100%;
        }
    }
    &[disabled] {
        opacity: .5;
    }
}
