.salarytable1{
	text-align: left;
}

.salarytable2{
	float: right;
}

.salaryexample{
	float: left;
}

.salarynormal{
	float: none;
}

.apprenticebox{
	padding: 0 0 0 70px;
}

.apprenticebox-fr{
    padding: 0 0 0 30px;
}

.apprenticetable {
    border: 0;

    td {
        text-align: right;
        padding: 0 .5em;
    }
}

.apprenticetitle{
	margin-top: 0.65em;
	font-size: 0.9em;
}

.salarytitle{
	margin: 1.4em 0 0.7em 0;
}

.salarytable0{
	// td:nth-of-type(2n+3){
	// 	background-color: $blue_light;
	// }
	// th:nth-of-type(2n+3){
	// 	background-color: $blue_light;
	// }
	//thead tr:first-child th {
		//background-color: $grey_light;
		//text-align: left;
	//}

    thead tr th:nth-of-type(n+2) {
        border-left-style: solid;
        border-right-style: solid;
        border-left-color: $grey;
        border-right-color: $grey;
        border-left-width: 1px;
        border-right-width: 1px;
    }

	thead tr th:first-child {
		background-color: white;
	}
    // thead tr th:last-of-type{
    //     border-right-style: solid;
    // }

    tbody tr td:nth-of-type(n+2){
        border-left-style: solid;
        border-right-style: solid;
        border-left-color: $grey;
        border-right-color: $grey;
        border-left-width: 1px;
        border-right-width: 1px;
    }
}


a.btn--salary-left, button.btn--salary-left {
    background: $grey-light;
    display: inline-block;
    color: $grey_darker;
    border-top-left-radius: 4px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 4px;
    padding: .7em 1.1em;
    text-decoration: none;
    border-style: none;
    user-select: none;
    display: inline-block;
    margin: -0.123em;
    font-weight: bold;

    vertical-align: middle;

    &:hover {
        background-color: $grey_dark;
        text-decoration: none;
    }
    &:active {
        background-color: $grey_darker;
    }

    &.btn-icon {
        padding: 0.2em;
        font-size: 1.4em;
        color: #FFFFFF;
        margin: 0;
    }

    &.btn-primary {
        background: $primary;
        color: #FFFFFF;
        cursor: default;
        box-shadow: 1px 1px 1px 1px $primary_dark inset;

        /*&:hover {
            background: $primary;
            //  background-image: linear-gradient(to bottom, $primary_light, $primary);
            background-color: $primary_dark;
            text-decoration: none;
        }
        &:active {
            background-color: $primary_darker;
        }*/
    }
}


.btn--label--salary-left {
/*    padding: .6em;
    margin: .3em;*/
    padding: 0 1em;
    display: inline-block;
    font-weight: bold;
    color: $primary_dark;
/*    vertical-align: top;*/
    position: absolute;
    line-height: 2em;
}

a.btn--salary-right, button.btn--salary-right {
    background: $grey_light;
    display: inline-block;
    color: $grey_darker;
    border-top-left-radius: 0px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 0px;
    padding: .7em 1.1em;
    text-decoration: none;
    border-style: none;
    user-select: none;
    display: inline-block;
    margin: -0.123em;
    font-weight: bold;

    vertical-align: middle;

    &:hover {
        background-color: $grey_dark;
        text-decoration: none;
    }
    &:active {
        background-color: $grey_darker;
    }

    &.btn-icon {
        padding: 0.2em;
        font-size: 1.4em;
        color: #FFFFFF;
        margin: 0;
    }

    &.btn-primary {
        background: $primary;
        color: #FFFFFF;
        cursor: default;
        box-shadow: 0px 1px 1px 1px $primary_dark inset;

        /*&:hover {
            background: $primary;
            //  background-image: linear-gradient(to bottom, $primary_light, $primary);
            background-color: $primary_dark;
            text-decoration: none;
        }
        &:active {
            background-color: $primary_darker;
        }*/
    }
}

.btn--label--salary-right {
/*    padding: .6em;
    margin: .3em;*/
    padding: 0 1em;
    display: inline-block;
    font-weight: bold;
    color: $primary_dark;
/*    vertical-align: top;*/
    position: absolute;
    line-height: 2em;
}

.salary_indent{
    tr:nth-child(2n+4){
        td:first-of-type{
            padding-left: 2em;
        }
    }
    // tr:nth-child(2n+3){
    //     td:last-of-type{
    //         border-bottom-style: solid;
    //     }
    // }

    td:nth-child(2n) {
        text-align: right;
    }
}

.salary_underlined{
    border-bottom-style: solid;
    border-bottom-color: $grey_dark;
    border-bottom-width: 1px;
}

@media ($mediaQueryPhone) {
    a.btn--salary-left, button.btn--salary-left{
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        width: 23em;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        text-align: center;
    }

    a.btn--salary-right, button.btn--salary-right {
        margin: 0;
        width: 23em;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
    }
}

