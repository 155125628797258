.apxTagList {
    display: inline-block;
    vertical-align: middle;
    font-size: .85em;
}

.apxTagList--list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        float: left;
        border: 1px solid $grey;
        margin: .3em;
        padding: .2em .8em;
        padding-right: 2.5em;

        position: relative;
    }

    .apxTagList--tag--delete {
        position: absolute;
        right: .6em;
        top: 0;
        bottom: 0;
        margin: auto;

        color: $text;
        cursor: pointer;
        display: block;
        padding: .2em;
        height: 1em;
    }
}