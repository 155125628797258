.ekim--header {

	.ekim--navigation--bottom {
		position: relative;

		.ekim--container {
			height: 100%;
		}
	}
}

.ekim--chapterNumber {
  display: inline-block;
  width: 3rem;
}

.ekim--container {
	max-width: 905px;
	position: relative;
	margin: auto;
}

.ekim--body {
    background-color: #fefefe;
    padding-top: 1px;
    position: relative;
}

.ekim--content {
 padding: 90px 90px 90px 90px;
  img {
	border: 1px solid $grey_light;
	margin: 10px 0;
  }
}

.ekim--breadcrumbs--container + .ekim--content {
  padding: 0px 90px 90px 90px;
}

.ekim--content--top-padding {
/*  	padding-top: 90px;*/
}

.ekim--content--panel {
	width: 400px;

    @media($mediaQueryPhone) {
        max-width: 400px;
        width: auto;
    }
}

.ekim--navigation--top {
  margin: 0 0 0 100px;
  z-index: 10;
/*  height: 40px;
  line-height: 40px;*/

  ol {
/*    margin: .65em 0;*/
    margin: 0;
  }


  a {
  	font-size: 0.85em;
	color: inherit;
	text-decoration: none;

	&:hover {
	  text-decoration: underline;
	}
  }
}

.ekim--navigation--top, .ekim--navigation-side {
  ol {
	padding: 0;
	display: inline;
	float: right;
  }
  ol:last-child {
	float:left;
  }

  .ekim--language-switch {
    margin-right: 20px;

    li {
      padding-right: 0;

      &:after {
        content: "|";
        padding-left: 4px;
        font-weight: normal;
      }

      &:last-child:after {
        display: none;
      }
    }

    .active {
      font-weight: bold;
    }
  }

  .ekim--navigation--top ol:last-child > li {
/*	color:red;*/
  }

  li {
	display: inline-block;
	list-style-type: none;
	padding-right: 20px;
    @media($mediaQueryPhone) {
      padding-right: 10px;
    }
    position: relative;
    height: 3em;
    line-height: 3em;
  }
}

li.ekim--navigation--top--topmenu > ol {
  display: none;
  padding: 1em;
  margin: -3px 0 0 0;
  width: 200px;
  box-shadow: 0px 0px 5px rgba(0, 0 , 0, 0.4);
  left: -100%;
}

li.ekim--navigation--top--topmenu > ol.active {
  display: block;
  z-index: 1337;
  position: absolute;
  background-color: white;
}

li.ekim--navigation--top--submenu {
  position: relative;
  display: block;
  clear: both;
  height: 1.5em;
  line-height: 1.5em;
  padding-right: 0;
  a {
    display: block;

  }
}

#mobileMenuButton {
  display: none;
  font-size: 2em;
  line-height: 1em;
  height: 1em;
  top: 7px;

  @media($mediaQueryPhone) {
      display: block;
  }
}

@media($mediaQueryPhone) {
  .ekim--navigation--top--desktopMenu li {
    display: none;
  }

  .ekim--navigation--top--desktopMenu li:last-child {
    display: inline;
  }
}

.ekim--navigation--top--mobileMenu {
  z-index: 5;
/*  position: absolute;*/
  width: 100%;
  background-color: white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  display: none;
  & > ol {
    padding: 10px;
    margin: 0;
  }
  & > ol > li {
    border-bottom: 1px solid $grey;
  }
  li {
    line-height: 2.5em;
    list-style-type: none;
  }
  a {
    color: $grey_darker;
    display: inline-block;
    width: 100%;
  }
}

#triangle {
  width: 0;
  height: 0;
  border-bottom: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin: -21px 0 0 0;
  position: absolute;
  left: 45%;
}

figure {
  margin: 0;
}

.ekim--header--img {
    position: relative;
    background-color: #ffffff;

    .ekim--header--img--bg-mobile {
        display: none;
    }

    img {
        float: right;
        max-width: none;
        visibility: hidden;
    }

    .ekim--header--img--logo {
        background: transparent url("../img/Metaltec_Suisse_RGB.png") top left no-repeat;
        height: 95%;
        width: 88.5%;

        position: absolute;
        top: 0;
        right: 0;
        margin: 2em 1em 1em 1em;
    }

    .ekim--header--img--menu {
        width: 100%;
        height: 80%;

        position: absolute;
        bottom: 0;
        right: 0;

        ul {
            position: absolute;
            bottom: 0;
            right: 1em;

            padding: 0;
            margin: 0;
            list-style: none;

            li {
                float: left;

                &.ekim--header--img--menu--active {
                    font-weight: bold;
                }
            }

            a {
                display: block;
                padding: 1em 1.5em;

                color: $text;
                text-align: center;
            }

            [class^="icon-"], [class*=" icon-"] {
                font-size: 2em;
                display: block;

                color: $primary;
            }
        }
    }
}

body.ekim--usertype-1 {
    .ekim--header--img--menu {
        li.ekim--header--img--menu--search {
            width: 100%;
        }
        li.ekim--header--img--menu--cart,
        li.ekim--header--img--menu--favorites {
            display: none;
        }
    }
}

@media screen and ($mediaQueryPhoneBigger) {
  .ekim--breadcrumbs--container + .ekim--content {
    padding: 0 45px 45px;
  }
  .ekim--navigation--top {
    margin: 0 0 0 45px;
  }
}

@media ($mediaQueryPhone) {

    $header_height: 58px;

    .ekim--header--img {
        .ekim--header--img--bg-desktop {
            display: none;
        }
        .ekim--header--img--bg-mobile {
            display: block;

            height: $header_height;
            width: 100%;
        }

        &.ekim--header--img--viewMode-1 {
          border-top: 4px solid #0E76C6;
        }

        &.ekim--header--img--viewMode-2 {
          border-top: 4px solid #CC0004;
        }

        .ekim--header--img--logo {
            background-position: center center;
            margin: 0;
            background-size: auto 70%;
            height: $header_height;
            width: 100%;
            right: 0;
        }
        .ekim--header--img--menu {
            background-color: transparent;
            background-image: linear-gradient(white 65%, $grey 100%);

            &,
            ul {
                position: static;
            }

            ul {
                a {
                    border-left: 1px solid $grey;
                    margin: .5em 0;
                    padding: 0 1.5em;
                }
            }

            li {
                width: 33.3%;
            }
        }
    }


    .ekim--navigation--top {
        margin-left: 20px;
      @media($mediaQueryPhone) {
        margin-left: 10px;
      }
    }

    .ekim--content {
        padding: 20px;
    }

    .ekim--breadcrumbs--container + .ekim--content {
        padding: 0 10px 20px;
    }
}

@media ($mediaQuerySmall) {
    .ekim--header--img {
        .ekim--header--img--menu {
            ul {
                a {
                    padding: 0 1em;
                }
            }
        }
    }
}
