/**
    General Typeahead styles
**/

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-dropdown-menu {
  width: 100%;
  margin-top: 1em;
  padding: .5em 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
  padding: .5em 1em;

  a {
      color: $primary_darker;

      display: block;
      padding-left: 4.8em;
      position: relative;
  }

  .ekim--product--material {
      position: absolute;
      left: 0;
      height: 1.2em;
      top: 0;
      bottom: 0;
      margin: auto;
  }
}

.tt-suggestion.tt-cursor {
  /*color: #fff;*/
  background-color: $grey;

}

.tt-suggestion p {
  margin: 0;
}


/**
    eKiM specific styles
**/
.ekim--search--form {

    .ekim--form--group {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: flex-start;
    }

    .twitter-typeahead {
        flex: 1 0 auto;
        margin-right: 1em;
    }

    input[type="search"] {
        width: 100%;
    }

    .btn.btn-primary {
        height: 1.35em;
        width: 1.6em;
    }
}

.ekim--search--container {
    display: none;

    padding: 0 90px;

    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 0;

    background: white;
    box-shadow: rgb(204, 204, 204) 0px -14px 31px -20px inset;
    border-bottom: 1px solid rgb(234, 234, 234);

/*    @media($mediaQueryTablet) {
        padding-left: 45px;
        padding-right: 45px;
    }*/
    @media($mediaQueryPhone) {
        padding-left: 10px;
        padding-right: 10px;
    }
}


.ekim--list.ekim--searchResults {
    li {
        white-space: normal;
    }

    a {
        display: block;
        padding-left: 4.8em;
        position: relative;
    }

    .ekim--product--material {
        position: absolute;
        left: 0;
        height: 1.2em;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}