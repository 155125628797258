@font-face {
	font-family: 'smu_iconfont';
	src:url('../iconfont/fonts/smu_iconfont.eot?1w09se');
	src:url('../iconfont/fonts/smu_iconfont.eot?#iefix1w09se') format('embedded-opentype'),
		url('../iconfont/fonts/smu_iconfont.woff?1w09se') format('woff'),
		url('../iconfont/fonts/smu_iconfont.ttf?1w09se') format('truetype'),
		url('../iconfont/fonts/smu_iconfont.svg?1w09se#smu_iconfont') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'smu_iconfont';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
	content: "\e606";
}

.icon-dropdown:before {
	content: "\e607";
}

.icon-checkbox:before {
	content: "\e608";
}

.icon-achtung:before {
	content: "\e600";
}

.icon-suche:before {
	content: "\e601";
}

.icon-star:before {
	content: "\e602";
}

.icon-notizen:before {
	content: "\e604";
}

.icon-info:before {
	content: "\e605";
}

.icon-warenkorb_leer:before {
	content: "\e603";
}

.icon-warenkorb_voll:before {
	content: "\e609";
}

.icon-trash:before {
	content: "\f014";
}

.icon-save:before {
	content: "\f0c7";
}

.icon-menu:before {
	content: "\e60a";
}
