@media print{

	.ekim--content {
		padding: 0 !important;
	}

	.ekim--header{
		display: none;
	}

	.btn.btn-primary{
		display: none;
	}

	a.btn{
		display: none;
	}

	td.ekim--table--actions{
		display: none;
	}

	.ekim--breadcrumbs--container{
		display: none;
	}

	.ekim--details--quantity{
		border: 0;
		background: transparent;
	}

	body{
		background: white;
	}

/*	h1:nth-of-type(n+2) {
		page-break-before: always;
	}*/

	input.ekim--details--quantity{
		-moz-appearance: textfield;
	}

	.ekim--productList--total{
		padding-right: 0.5cm;
	}

    .ekim--cart--switchView {
      display: none;
    }
    .ekim--table {
      th, td{
        border-bottom: 1px solid $grey;
      }
      a {
        color: $primary_darker;
      }
    }

}

@page {
	margin: 2cm 1.5cm;
}
