.ekim--table {
    width: 100%;
    text-align: left;
    border-spacing: 0;
    margin: 0 0 2em 0;
    overflow-x: auto;

    thead th:first-child {
        padding-left: 0;
    }

    tbody tr:nth-child(odd) {
        background-color: $grey_light;
    }

    tbody + tbody {
        border-top: 2.5em solid white;
    }

    th, td {
        padding: .4em .4em .4em .8em;
    }

    th {
        background-color: white;
        color: $grey_darker;
        font-size: .8em;
        font-weight: normal;
    }

    th[rowspan] {
        vertical-align: top;
    }

    td {
        color: $primary_darker;
    }

    .ekim--table--actions {
        text-align: right;
        font-size: .8em;
    }

    .ekim--table--currency {
        text-align: right;
    }

    caption {
        caption-side: bottom;
        text-align: left;
        i {
          font-size: .8em;
        }
    }

    @media($mediaQueryPhone) {
        .ekim--table {
            display: block;
        }
    }
}

.ekim--table--title {
/*    padding: 0 0 0 .7em;*/
}

.ekim--table--favorites {

}

.ekim--table--mobileWrapper {
  width: 100%;
  overflow: auto;
}

.ekim--table--price {
    text-align: right;
}

.dt-buttons {
    float: right;
}