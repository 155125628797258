%apxForm_conditional_transition {
    transition:
        visibility .3s,
        opacity .3s
}

.apxForm--conditional-hidden {
    //visibility: hidden;
    display: none;
    opacity: 0;
}
.apxForm--conditional-visible {
    //visibility: visible;
    display: block;
    opacity: 1;
}

.apxForm--error {
    display: none;

    &.apxForm--error-visible {
        display: block;
    }
}