.alert {
    clear: both;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid transparent;

    h4 {
        margin-top: 0;
        margin-bottom: .6em;
        color: inherit;
    }
    .alert-link {
        font-weight: bold;
    }
    > p,
    > ul {
        margin: 0;
    }
    > p + p {
        margin-top: 5px;
    }
}
/*.alert-dismissable,
.alert-dismissible {
    padding-right: 35px;
}
.alert-dismissable .close,
.alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
}*/
.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    /*color: #468847;*/

    hr {
        border-top-color: #c9e2b3;
    }
    .alert-link {
        color: #356635;
    }
}
.alert-info {
    background-color: #dce9f0;
    border: none;
    font-size: .8em;
    /*color: #3a87ad;*/

    hr {
        border-top-color: #a6e1ec;
    }
    .alert-link {
        color: #2d6987;
    }
}
.alert-warning {
    background-color: #fcf8e3;
    border-color: #fbeed5;
    /*color: #c09853;*/

    hr {
        border-top-color: #f8e5be;
    }
    .alert-link {
        color: #a47e3c;
    }
}
.alert-error,
.alert-danger {
    background-color: #f2dede;
    border-color: #eed3d7;
    /*color: #b94a48;*/

    hr {
        border-top-color: #e6c1c7;
    }
    .alert-link {
        color: #953b39;
    }
}
