.info_rail_tag{
	text-align: right;
}

.info_rail_title{
	margin: 1.8em 0 0 0;
}

.info_rail_table{
	width: 100%;
    text-align: left;
    border-spacing: 0;
    margin: 0 0 2em 0;

    tbody tr:nth-child(odd) {
        background-color: $grey_light;
    }

    th, td {
        padding: .4em .4em .4em .8em;
    }

    th {
        background-color: white;
        color: $grey_darker;
        font-size: .8em;
        font-weight: normal;
    }

    td {
        color: $primary_darker;
    }
}
