.ekim--details--notes {
    border: 1px solid $grey;
    margin: 20px 0 20px 0;

    h3 {
        background-color: $grey_light;
        margin: 0;
        padding: 10px;
        position: relative;
    }

    .ekim--details--notes--icon {
        position: absolute;
        right: .5em;
        top: 0;
        bottom: 0;

        color: $grey_dark;
        font-size: 1.4em;
        height: 1em;
        margin: auto;
    }

    .ekim--details--notes--icon--save {
        color: $primary_darker;
    }

    p, textarea {
        padding: 1em;
/*        margin: 1em 0;*/
        margin: 0;
    }

    textarea {
        display: block;
        box-sizing: border-box;
/*        margin: 13px -0.359375px 13px 0px;*/
        resize: vertical;
        width: 100%;
        border-color: $primary_darker;
    }
}

textarea {
    margin: 0;
}

.ekim--productFavorite-on {
    .ekim--productFavorite--off {
        display: none;
    }
}
.ekim--productFavorite-off {
    .ekim--productFavorite--on {
        display: none;
    }
}

#productNumber {
    /*  font-weight: bold;*/
    color: $primary_darker;
}

#quantity, #discount {
  display: inline-block;
  margin: 0 0 1em 0;
}

#discount {
  margin: 0 0 0 2em;
}

#discount.clearMargin {
  margin: 0;
}

.ekim--details--quantity,
.ekim--details--discount {
    width: 3em;
    width: 2.5em;
}
.ekim--details--clearQuantity,
.ekim--details--clearDiscount {
    color: $grey_darker;
    //padding: .5em;
    font-size: 1.4em;
    vertical-align: sub;

    &:hover {
        color: $primary;
    }
}

.ekim--details--prices {
    margin-bottom: 0;
}
.ekim--details--prices--list {
    font-size: 1.6em;

    a {
        color: $grey_dark;
        cursor: pointer;
        position: relative;
        font-size: 1.1em;

        &:hover {
            color: $primary;
        }
    }

    .ekim--details--cartAmount {
        position: absolute;
        right: -.2em;
        bottom: -.2em;
        background: $primary;
        border-radius: 1em;
        color: white;
        font-weight: bold;
        font-size: .5em;
        line-height: 1em;
        text-align: center;
        padding: .15em .3em;

        &:empty {
            display: none;
        }
    }
}
