.hint {
  color: $primary;
}

.hint-sia {
  margin-top: -0.7em;
  font-style: italic;
  font-size: .8em;
}

.hint.sia.approved{}

.hint-sia.notApproved{
  color: $error;
}

.hint-info {
/*  color: $grey_darker;*/
}

.hint-material {
  padding: .2em 0;
  margin: 0 0.6em 0 0;
  display: inline-block;
  width: 55px;
  text-align: center;
  text-transform: uppercase;
}

.hint-icon.icon-info:before, .hint-icon.icon-achtung:before {
  font-size: 1.6em;
  position: relative;
  top: -2px;
  vertical-align: middle;
  padding: 0 .1em 0 0;
}

.aluminium {
  @extend .hint-material;
  background-color: #FFD959;
}

.steel {
  @extend .hint-material;
  background-color:#87BFFF;
}

.stainless {
  @extend .hint-material;
  background-color:#FFAC5A;
}

.miscellaneous {
  @extend .hint-material;
  background-color:#BBBBBB;
}

.wood {
  @extend .hint-material;
  background-color:#CEAE92;
}

.hint-text{
/*  color: $text;*/

  @media($mediaQueryPhone)
  {
    margin: 0;
  }
}
