.ekim--list {
  display: inline-block;
  width: 100%;
  position: relative;
}

.ekim--list--id {
  display: inline-block;
  width: 3em;
}

.ekim--list,
.ekim--list a {
    color: $primary_darker;
}
.ekim--list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ekim--list li {
  display: inline-block;
  width: 100%;
  float: left;
  box-sizing: border-box;
}
.ekim--list ul > li > ul >li {
/*  color: black;*/
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:empty:before {
    content: "\00a0";
  }
}

.ekim--list ul > li > ul >li:nth-child(odd) {
  background-color: $grey_light;
}

/*TODO: This should be in the grid module, refactor this*/

div.ekim--list .ekim--col-1-12 {
  width: 8.33%;
}

div.ekim--list .ekim--col-2-12 {
  width: 16.66%;
}

div.ekim--list .ekim--col-3-12 {
  width: 25%;
}

div.ekim--list .ekim--col-4-12 {
  width: 33.33%;
}

div.ekim--list .ekim--col-5-12 {
  width: 41.66%;
}

div.ekim--list .ekim--col-6-12 {
  width: 50%;
}

div.ekim--list .ekim--col-7-12 {
  width: 58.33%;
}

div.ekim--list .ekim--col-8-12 {
  width: 66.66%;
}

div.ekim--list .ekim--col-9-12 {
  width: 75%;
}

div.ekim--list .ekim--col-10-12 {
  width: 83.33%;
}

div.ekim--list .ekim--col-11-12 {
  width: 91.66%;
}

div.ekim--list .ekim--col-12-12 {
  width: 100%;
}

@media screen and ($mediaQueryPhone) {
  div.ekim--list li {
    float: none;
  }
  div.ekim--list .ekim--col-1-12,
  div.ekim--list .ekim--col-2-12,
  div.ekim--list .ekim--col-3-12,
  div.ekim--list .ekim--col-4-12,
  div.ekim--list .ekim--col-5-12,
  div.ekim--list .ekim--col-6-12,
  div.ekim--list .ekim--col-7-12,
  div.ekim--list .ekim--col-8-12,
  div.ekim--list .ekim--col-9-12,
  div.ekim--list .ekim--col-10-12,
  div.ekim--list .ekim--col-11-12 {
    width: 100%;
  }

  .ekim--list ul > li > ul > li:empty {
    display: none;
  }

  .ekim--list-column-count-odd {
    ul > li:nth-child(even) > ul >li:nth-child(odd) {
      background-color: transparent;
    }

    ul > li:nth-child(even) > ul >li:nth-child(even) {
      background-color: $grey_light;
    }
  }
}

.ekim--list--empty {
    display: none;
}

.ekim--list-empty {
    .ekim--list--empty {
        display: block;
    }
}

.ekim--list--loader {
    /*display: none;*/
    background: white url(../img/loading.gif) center 1em no-repeat;
    opacity: 0;

    transition: opacity .5s, z-index .5s;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.ekim--list-loading {
    .ekim--list--loader {
        /*display: block;*/
        opacity: .6;
        z-index: 1;
    }
}
