.apxDropdown {
    position: relative;
    z-index: 3;
    display: inline-block;
    font-size: .85em;
    margin: .3em .4em .3em 0;
    vertical-align: middle;
    user-select: none;
    @media($mediaQueryPhone) {
      width: 100%;
    }

    &.active {
        z-index: 4;
    }

    &select {
        visibility: hidden;
    }
}

.apxDropdown-single input {
    display: none;
}

form.apxDropdown {
    margin: 0 0 25px 0;
}

.apxDropdown--dropdown {
    cursor: default;
    overflow: auto;
    border: 1px solid $grey;
    min-width: 150px;
    max-width: 250px;
    float: left;
    z-index: 2;
    position: relative;
    background-color: white;
    @media($mediaQueryPhone) {
      width: 100%;
      max-width: none;
    }

    div:first-child {
        float: left;
        line-height: 2.6em;
        padding: 0 0 0 0.8em;
        background: white;
    }

    div:last-child {
        background-color: $primary;
        color: white;
        float: right;
        font-size: 1.2em;
        width: 30px;
        text-align: center;
        line-height: 2.17em;
    }

}

.apxDropdown--list {
    position: absolute;
    background-color: white;
    border: 1px solid $grey;
    min-width: 150px;
    max-width: 250px;
    overflow: auto;
    top: 20px;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition-duration: 0.2s;
    z-index: -1;
    @media($mediaQueryPhone) {
      width: 100%;
      max-width: none;
    }

    input {
        margin-right: .5em;
    }

    .apxDropdown.active & {
        opacity: 1;
        pointer-events: initial;
        visibility: visible;
        top: 25px;
        z-index: 1;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: .8em 0 .4em;
    }

    li {
        padding: 0 .8em;

        &:hover {
            background-color: #f2f2f2;
        }
    }

    label {
        display: block;
        line-height: 2.2em;
    }

}
