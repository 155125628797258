.ekim--row {
  position: relative;
/*  overflow: hidden;*/
}

/* Clearfix */
.ekim--row:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.ekim--row--space {
/* margin: 5px;*/
 padding: 10px;
  box-sizing: border-box;
}

.ekim--row--rightspace {
  padding-right: 30px;
    @media($mediaQueryPhone) {
        padding-right: 0;
    }
}

@for $i from 12 through 12 {
  @for $j from 1 through 12 {
    .ekim--col-#{$j}-#{$i} {
      @extend %mediaQueries;
      @extend %column;
      width: 100 / $i * $j + 0%;
    }
  }
}

%column {
  float: left;
  box-sizing: border-box;
}

%mediaQueries {
    @media($mediaQueryPhone) {
        float: none;
        width: 100%;
        margin-bottom: 1em;
    }
}
